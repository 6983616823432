import { useState } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import 'primereact/resources/themes/lara-light-cyan/theme.css';

import ExcelDownloader from '../excelDownloader';


function PrimeTableSimple(props) {

  // Define variables
  const [expandedRows, setExpandedRows] = useState(null);
  // const [activSearch, setActivSearch] = useState(false);
  // const [loading, setLoading] = useState(false);
  const data = props.data;
  const columns = props.columns;
  const showResponses = props.showResponses;
  const allowExpansion = props.allowExpansion;
  const rowExpansionTemplate = props.rowExpansionTemplate;
  // const enableSearch = props.enableSearch || true;
  // const searchPlaceholder = props.searchPlaceholder || 'Search';
  const filename = props.filename || 'export';
  const showExport = props.showExport || false;


  // Screen components
  let expanderColumn;
  if( showResponses ) {
    expanderColumn = <Column expander={allowExpansion} style={{ width: '5rem' }} />;
  }

  let rowStyle;
  let downloadElement;
  if( showExport ) {
    rowStyle = {marginBottom: '15px'};
    downloadElement = <ExcelDownloader data={data} headings={columns} filename={filename} />;
  }


  return (
    <div style={{width: '100%'}}>

      <div class='flex-row' style={rowStyle}>
        {downloadElement}
        {/* {searchElement} */}
      </div>

      <div class='prime-table-simple-wrapper'>
        <DataTable
          value={data}
          // loading={loading}
          stripedRows
          // sortField={'date'}
          // sortOrder={'asc'}
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
          emptyMessage="No records found"
        >
          {columns.map((column, i) => (
            <Column
              key={column.name}
              field={column.name}
              header={column.display}
              sortable={column.sortable}
              body={column.body ? column.body : null}
              headerStyle={column.headerStyle}
              bodyStyle={column.bodyStyle}
              dataType={column.dataType}
            />
          ))}
          {expanderColumn}
        </DataTable>
      </div>

    </div>
  );
}

export default PrimeTableSimple;