import { searchCodes } from "../utilities/auth/authAPI";
import PrimeTable from "../utilities/table/primeTable";


function AssessCodes(props) {

  // Define variables
  const organizationId = props.organizationId;
  const viewCode = props.viewCode;

  const viewBody = (item) => {

    const assessmentId = item.assessmentId;
    let style;

    if( !assessmentId ) {
      style = {display: 'none'};
    }

    return (
      <button
        onClick={() => viewCode(item)}
        class='icon icon-view-fill'
        title='View'
        style={style}
      />
    );
  }

  const columns = [
    {name: 'code', display: 'Code', sortable: true},
    {name: 'status', display: 'Status', sortable: true},
    {name: 'date', display: 'Last Update', sortable: true},
    {name: 'view', display: '', sortable: false, body: viewBody, bodyStyle: {textAlign: 'center'}}
  ];


  return (
    <div style={{width: '100%'}}>
      <PrimeTable
        columns={columns}
        organizationId={organizationId}
        apiMethod={searchCodes}
        searchPlaceholder={'Search by code'}
      />
    </div>
  );
}

export default AssessCodes;