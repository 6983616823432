import { searchAssessments } from "../utilities/auth/authAPI";
import PrimeTable from "../utilities/table/primeTable";


function AssessList(props) {

  // Define variables
  const organizationId = props.organizationId;
  const viewCode = props.viewCode;

  const viewBody = (item) => {
    return (
      <button
        onClick={() => viewCode(item)}
        class='icon icon-view-fill'
        title='View'
      />
    );
  }

  const columns = [
    {name: 'status', display: 'Status', sortable: true},
    {name: 'code', display: 'Code', sortable: true},
    {name: 'date', display: 'Last Update', sortable: true},
    {name: 'firstName', display: 'First Name', sortable: true},
    {name: 'lastName', display: 'Last Name', sortable: true},
    {name: 'email', display: 'Email', sortable: true},
    {name: 'cefr', display: 'Score', sortable: true},
    {name: 'view', display: '', sortable: false, body: viewBody, bodyStyle: {textAlign: 'center'}}
  ];


  return (
    <div style={{width: '100%'}}>
      <PrimeTable
        columns={columns}
        organizationId={organizationId}
        apiMethod={searchAssessments}
        searchPlaceholder={'Search by code, name, or email'}
      />
    </div>
  );
}

export default AssessList;