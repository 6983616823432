import React from 'react';
import { useState, useEffect } from 'react';

import { Loading } from '../helpers';
import TableLearnersRow from './tableLearnersRow';
import ExcelDownloader from '../excelDownloader';


function TableLearners(props) {

  // Define variables
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const data = props.data;
  const title = props.title;
  const enableSearch = props.enableSearch || undefined;
  const viewFunction = props.viewFunction || undefined;
  const editPasswordFunction = props.editPasswordFunction || undefined;
  const editInfoFunction = props.editInfoFunction || undefined;
  const removeSeatFunction = props.removeSeatFunction || undefined;
  const headings = props.headings;
  const isLoading = props.isLoading;
  const showCourseActivityColumn = props.showCourseActivityColumn;


  // Page load actions
  useEffect(() => {
    setFilteredData(data);
  }, [data]);


  // Define functions
  function search(value) {
    setSearchText(value);

    let results = [];

    data.find(function(object) {
      for(var i=0; i<headings.length; i++) {

        const thisValueRaw = object[headings[i].name];
        const thisValue = thisValueRaw ? thisValueRaw.toString().toLowerCase() : '';

        if( thisValue.includes(value.toLowerCase().trim()) ) {
          results.push(object);
          break;
        }
      }
    });

    if( results ) {
      setFilteredData(results);
    }
  }

  function sort(field) {
    const baseData = [...filteredData];
    let sortedData = baseData.sort((a, b) => a[field] > b[field] ? 1 : -1);

    if( ['score', 'averageScore', 'lastLessonCompleteDate'].includes(field) ) {
      sortedData = sortedData.reverse();
    }

    setFilteredData(sortedData);
  }


  // Screen components
  let searchElement;
  if( enableSearch ) {
    searchElement = (
      <input
        name="search"
        type="text"
        value={searchText}
        onChange={event => search(event.target.value)}
        placeholder='Search'
        title="Type to search"
      />
    );
  }

  let sortElement;
  if( true ) {
    sortElement = (
      <div class='flex-row'>
        <p>Sort by&nbsp;</p>
        <select
          onChange={(event) => sort(event.target.value)}
        >
          <option value='firstName'>First Name</option>
          <option value='lastName'>Last Name</option>
          <option value='email'>Email</option>
          <option value='score'>Confidence Level</option>
          <option value='averageScore'>Average Score</option>
          <option value='lastLessonCompleteDate'>Last Lesson Date</option>
        </select>
        <span style={{width: '30px'}}></span>
      </div>
    );
  }

  let titleElement;
  if( title ) {
    titleElement = <h3 style={{marginRight: '15px'}}>{title}</h3>;
  }

  let loadingElement;
  if( isLoading ) {
    loadingElement = <div style={{paddingTop: '120px'}}><Loading /></div>;
  }

  let blankStateElement;
  if( !isLoading && filteredData.length === 0 ) {
    blankStateElement = <div>{props.blankStateWording ? props.blankStateWording : 'There are no records'}</div>;
  }

  let downloadElement;
  if( data.length > 0 ) {
    downloadElement = <ExcelDownloader data={data} headings={headings} filename={'Learner Data'} />;
  }

  let tableHeaderElement;
  if( !isLoading && data.length > 0 ) {

    let courseRow;
    if( showCourseActivityColumn ) {
      // courseRow = <div class='table-learner-row-data'>Course Activity</div>;
      courseRow = <div class='table-learner-row-data'>Activity</div>;
    }

    tableHeaderElement = (
      <div class='flex-row table-learner-row header'>
        <div class='table-learner-row-data'>Learner</div>
        {courseRow}
        {/* <div class='table-learner-row-data'>Lesson Activity</div> */}
        <div class='table-learner-row-data'>Stats</div>
        <div class='table-learner-row-actions'>&nbsp;</div>
      </div>
    );
  }


  return(
    <div class='table-wrapper'>

      <div class='flex-row' style={{marginBottom: '10px'}}>
        <div class='flex-row'>
          {titleElement}
          {downloadElement}
        </div>
        <div class='flex-row'>
          {sortElement}
          {searchElement}
        </div>
      </div>

      <div>

        {loadingElement}
        {blankStateElement}
        {tableHeaderElement}

        {filteredData.map((object, index) =>
          <TableLearnersRow
            key={`cell-learner-${index}`}
            object={object}
            viewFunction={viewFunction}
            editPasswordFunction={editPasswordFunction}
            editInfoFunction={editInfoFunction}
            removeSeatFunction={removeSeatFunction}
            showCourseActivityColumn={showCourseActivityColumn}
          />
        )}
      </div>

    </div>
  );
}

export default TableLearners;