import AssessResponse from "./assessResponse";


function AssessResponses(props) {

  // Define variables
  const assessment = props.assessment;
  const responses = assessment.responses || [];


  return (
    <div class='assess-responses-container'>
      <h3>Response Details</h3>
      {responses?.map((response, index) =>
        <AssessResponse key={`response-card-${index}`} response={response} />
      )}
    </div>
  );
}

export default AssessResponses;