import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Nav from './nav';


function Header() {

  // Define variables
  const navigate = useNavigate();
  const profileData = useSelector((state) => state.profile);
  const isSuperAdmin = useSelector((state) => state.profile.role) === 'superAdmin';


  // Screen componets
  let navElement;
  if( profileData.initalized ) {
    navElement = (
      <Nav
        profileData={profileData}
        isSuperAdmin={isSuperAdmin}
      />
    );
  }


  return(
    <div class='header flex-column'>
      <div class='header-container flex-row'>

        <button
          class='flex-row'
          onClick={() => navigate('/')}
          style={{padding: '15px'}}
        >
          <div class='logo-lockup'></div>
          <h3 style={{
            fontSize: '1.5rem',
            color: '#9610C3'
          }}>Admin</h3>
        </button>

        {navElement}

      </div>
    </div>
  );
}

export default Header;