import { useState, useEffect } from 'react';

import toast from 'react-hot-toast';
import apiCall from '../../helperFunctions/apiCall';
import { getAssessment } from '../utilities/auth/authAPI';
import { Loading } from '../utilities/helpers';

import AsessCard from './assessCard';
import AssessResponses from './assessResponses';
import ButtonClose from '../utilities/buttonClose';


function ViewAssessment(props) {

  // Define variables
  const [loading, setLoading] = useState(true);
  const [learnerInfo, setLearnerInfo] = useState({});
  const [assessments, setAssessments] = useState([]);
  const id = props.id;
  const closeModal = props.closeModal;

  let highlightedAssessment;
  if( assessments.length === 1 ) {
    highlightedAssessment = assessments[0];
  } else {
    highlightedAssessment = assessments.find(a => a.highlighted === true);
  }


  // Page load actions
  useEffect(() => {

    const payload = {id: id};

    apiCall(getAssessment, payload).then((response) => {
      if( response.success ) {
        setLearnerInfo(response.data.learnerInfo);
        setAssessments(response.data.assessments);
        setLoading(false);
      } else {
        toast.error(response.error);
        closeModal();
      }
    });
  }, [id]);


  // Screen components
  let loadingElement;
  if( loading ) {
    loadingElement = (
      <div style={{marginTop: '150px'}}>
        <Loading />
      </div>
    );
  }

  let responsesElement;
  if( highlightedAssessment && highlightedAssessment?.responses?.length > 0 ) {
    responsesElement = <AssessResponses assessment={highlightedAssessment} />;
  }


  return (
    <div class='modal-wrapper'>
      <div class='modal-container'>

        <div class='modal-flex-container'>

          <div class='modal-header'>

            <div>
              <h4>{learnerInfo.heading}</h4>
              <p>{learnerInfo.subHeading}</p>
            </div>

            <div>
              <button
                class='icon icon-printer'
                onClick={() => window.print()}
                title='Print'
                style={{marginRight: '15px'}}
              />

              <ButtonClose closeAction={closeModal} />
            </div>

          </div>

          <div class='modal-body'>
            {loadingElement}

            <div class='flex-row assess-card-container'>
              {assessments?.map((assessment, index) =>
                <AsessCard key={`assess-card-${index}`} assessment={assessment} />
              )}
            </div>

            {responsesElement}

          </div>

        </div>
      </div>
    </div>
  );
}

export default ViewAssessment;