import AssessResponseScore from "./assessResponseScore";
import AudioIcon from "../utilities/audioIcon";


function AssessResponse(props) {

  // Define variables
  const response = props.response;
  const title = response.title;
  const transcript = response.transcript;
  const prompt = response.prompt;
  const audioUrl = response.audioUrl;

  const pronunciation = response.pronunciation;
  const grammar = response.grammar;
  const vocabulary = response.vocabulary;
  const fluency = response.fluency;
  const relevance = response.relevance;
  const didNotRecord = response.didNotRecord;


  // Screen components
  let promptElement;
  if( prompt ) {
    promptElement = (
      <div>
        <p class='assess-score-heading'>Question</p>
        <p><i>{prompt}</i></p>
      </div>
    );
  }

  let audioElement;
  if( audioUrl ){
    audioElement = <div style={{marginTop: '15px', marginLeft: '5px'}}><AudioIcon audioUrl={audioUrl} /></div>;
  }

  let bodyElement;
  if( didNotRecord ) {
    bodyElement = (
      <div>
        <p><i>The learner did not record withing the allowed time, so this response was skipped.</i></p>
      </div>
    );
  } else {
    bodyElement = (
      <div>
        <div>

          <div class='flex-row' style={{justifyContent: 'flex-start'}}>
            <p class='assess-score-heading'>Transcript</p>
            {audioElement}
          </div>

          <p><i>{transcript}</i></p>
        </div>

        <AssessResponseScore title={'Pronunciation'} score={pronunciation} />
        <AssessResponseScore title={'Grammar'} score={grammar} />
        <AssessResponseScore title={'Vocabulary'} score={vocabulary} />
        <AssessResponseScore title={'Fluency'} score={fluency} />
        <AssessResponseScore title={'Relevance'} score={relevance} />
      </div>
    );
  }


  return (
    <div class='assess-response'>
      <h4 style={{marginBottom: '30px'}}>{title}</h4>
      {promptElement}
      {bodyElement}
    </div>
  );
}

export default AssessResponse;