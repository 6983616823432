import ViewLearnerCourse from "./viewLearnerCourse";


function ViewLearnerCourses(props) {

  // Define variables
  const headings = props.headings;
  const courseData = props.courseData;
  const firstName = props.firstName;
  const showResponses = props.showResponses;
  const learnerId = props.learnerId;
  const columns = props.columns;
  const allowExpansion = props.allowExpansion;
  const rowExpansionTemplate = props.rowExpansionTemplate;


  // Screen components
  let blankStateElement;
  if( !courseData || courseData.length === 0 ) {
    blankStateElement = <p>No Courses have been started or completed</p>;
  }


  return (
    <div>
      {blankStateElement}
      {courseData.map((course, index) =>
        <ViewLearnerCourse
          key={`course-${index}`}
          course={course}
          headings={headings}
          firstName={firstName}
          showResponses={showResponses}
          learnerId={learnerId}
          columns={columns}
          allowExpansion={allowExpansion}
          rowExpansionTemplate={rowExpansionTemplate}
        />
      )}
    </div>
  );
}

export default ViewLearnerCourses;