import apiCall from '../../helperFunctions/apiCall';

import * as FileSaver from 'file-saver';
const XLSX = require('sheetjs-style');



function ExcelDownloader(props) {

  // Define variables
  const headings = props.headings;
  const excelData = props.data ? formatTitles(props.data, headings) : [];
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8';
  const fileName = props.filename || 'export';
  const fileExtension = '.xlsx';
  const apiMethod = props.apiMethod;
  const organizationId = props.organizationId;
  const sortField = props.sortField;
  const sortOrder = props.sortOrder;


  // Define functions
  function handleClick() {
    if( apiMethod && organizationId ) {
      const payload = {
        organizationId: organizationId,
        limit: 10000,
        page: 0,
        sortField: sortField,
        sortOrder: sortOrder
      }

      apiCall(apiMethod, payload).then((response) => {
        if( response.success ) {
          const data = formatTitles(response.data.data, headings);
          exportToExcel(data);
        } else {
          alert(response.error);
        }
      });
    } else {
      exportToExcel(excelData);
    }
  }

  async function exportToExcel(providedData) {
    const ws = XLSX.utils.json_to_sheet(providedData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  }


  return (
    <button
      type='button'
      class='button export'
      title='Export all records'
      onClick={() => handleClick()}
    >Export</button>
  );

}

export default ExcelDownloader;


// --

function formatTitles(array, headings) {
  const newArray = [];

  array.forEach((item, index) => {
    const newObject = {}
    headings.forEach((heading, headingIndex) => {
      newObject[heading.display] = item[heading.name];
    });

    newArray.push(newObject);
  });

  return newArray;
}