import PrimeTableSimple from '../utilities/table/primeTableSimple';


function ViewLearnerLessons(props) {

  // Define variables
  const lessonData = props.lessonData;
  const firstName = props.firstName;
  const showResponses = props.showResponses;
  const columns = props.columns;
  const filename = `Lessons - ${firstName}`;
  const allowExpansion = props.allowExpansion;
  const rowExpansionTemplate = props.rowExpansionTemplate;

  // Format the lesson data
  const data = [];

  lessonData.forEach((lesson, index) => {
    data.push(
      {
        exerciseId: ( lesson.complete === true ) ? lesson.exerciseId : null,
        title: lesson.title,
        complete: lesson.complete === true ? true : false,
        time: lesson.completedDuration.replace('minute', 'min'),
        retries: lesson.retries,
        score: lesson.score,
        date: new Date(lesson.completedOn)
      }
    )
  });


  return (
    <PrimeTableSimple
      columns={columns}
      data={data}
      allowExpansion={allowExpansion}
      rowExpansionTemplate={rowExpansionTemplate}
      searchPlaceholder={'Search by code, name, or email'}
      showResponses={showResponses}
      showExport={true}
      filename={filename}
    />
  );
}

export default ViewLearnerLessons;