import { useState, useEffect } from 'react';

import toast from 'react-hot-toast';
import apiCall from '../../helperFunctions/apiCall';
import { getLearnerResponses } from '../utilities/auth/authAPI';

import ViewResponse from './viewResponse';


function ViewResponses(props) {

  // Define variables
  const [responses, setResponses] = useState([]);
  const [lessonType, setLessonType] = useState(null);
  const learnerId = props.learnerId;
  const data = props.data;
  const exerciseId = data.exerciseId;


  // Component load actions
  useEffect(() => {
    const payload = {id: learnerId, exerciseId: exerciseId};

    apiCall(getLearnerResponses, payload).then((response) => {
      if( response.success ) {

        // Not all exercises have responses
        if( response.data ) {
          setResponses(response.data.responses);
          setLessonType(response.data.lessonType);
        }

      } else {
        toast.error(response.error);
      }
    });
  }, [data]);


  // Screen components
  let blankStateElement;
  if( responses.length === 0 ) {
    blankStateElement = <p>There are no responses for this lesson.</p>;
  }

  let lessonTypeElement;
  let titleElement;
  if( lessonType ) {
    lessonTypeElement = <span> for <i>{lessonType}</i> lesson</span>;
  }


  return (
    <div class='show-responses-container'>
      <p><strong>Learner Responses</strong>{lessonTypeElement}</p>
      {responses.map((response, i) => (
        <ViewResponse key={`response-${i}`} response={response} />
      ))}
      {blankStateElement}
    </div>
  );
}

export default ViewResponses;