import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Loading } from '../utilities/helpers';
import Header from '../utilities/header';
import Footer from '../utilities/footer';
import OrganizationCard from '../utilities/organizationCard';
import AssessList from './assessList';
import AssessCodes from './assessCodes';
import ViewAssessment from './viewAssessment';


function AssessmentsScreen() {

  // Define variables
  const organizationId = useSelector((state) => state.profile.organizationId);
  const assessmentsEnabled = useSelector((state) => state.profile.assessmentsEnabled);
  const [viewAssessmentElement, setViewAssessmentElement] = useState(null);
  const [selectedTab, setSelectedTab] = useState('assessment');
  const [loading, setLoading] = useState(!assessmentsEnabled);


  // Component load actions
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);


  // Define functions
  function viewCode(item) {
    const id = item.assessmentId ? item.assessmentId : item.id;
    setViewAssessmentElement(
      <ViewAssessment
        id={id}
        closeModal={() => setViewAssessmentElement(null)}
      />
    );
  }


  // Screen components
  let bodyContent = (
    <div class='flex-column' style={{width: '100%', marginTop: '120px'}}>
      <Loading />
    </div>
  );

  if( assessmentsEnabled ) {
    if( selectedTab === 'assessment' ) {
      bodyContent = <AssessList organizationId={organizationId} viewCode={viewCode} />;
    } else if( selectedTab === 'code' ) {
      bodyContent = <AssessCodes organizationId={organizationId} viewCode={viewCode} />;
    }
  } else {
    if( !loading ) {
      bodyContent = (
        <div class='flex-column' style={{width: '100%'}}>
          <div class='flex-column assess-blank-state'>
            <p><strong>Your organization has not set up Assessments yet.</strong></p>
            <br />
            <p>The Assessment tool quickly evaluates your learners' speaking levels in under 10 minutes.</p>
            <br />
            <p>Contact <a href='mailto:support@flowspeak.io'>support@flowspeak.io</a> to get started.</p>
          </div>
        </div>
      );
    }
  }



  return (
    <div class='body-wrapper flex-column'>
      {viewAssessmentElement}

      <Header />
      <OrganizationCard />

      <div class='body-container'>

        <div>

          <div class='flex-row' style={{justifyContent: 'flex-start', marginBottom: '15px'}}>

            <button class={`assess-sub-head-button ${selectedTab === 'assessment' ? 'selected' : null}`} type='button' onClick={() => setSelectedTab('assessment')}>
              <p>Assessments</p>
            </button>

            <button class={`assess-sub-head-button ${selectedTab === 'code' ? 'selected' : null}`} type='button' onClick={() => setSelectedTab('code')}>
              <p>Codes</p>
            </button>

          </div>

          <div class='flex-row flex-wrap'>
            {bodyContent}
          </div>
        </div>
      </div>

      <Footer />

    </div>
  );
}

export default AssessmentsScreen;