import { useState, useEffect } from 'react';

import ViewLearnerLessons from './viewLearnerLessons';
import ViewLearnerCourses from './viewLearnerCourses';
import ViewResponses from './viewResponses';


function ViewLearnerData(props) {

  // Define variables
  const [activeTab, setActiveTab] = useState(null);
  const [activeContent, setActiveContent] = useState(null);
  const learner = props.learner;
  const firstName = learner.firstName;
  const learnerId = learner.id;

  const completeBody = (item) => {
    const className = item.complete === true ? 'icon icon-circle-ok' : null;
    return <div class={className}></div>;
  }

  const dateBody = (item) => {
    let value = '';
    if( item.date ) {
      value = item.date.toLocaleDateString('en-US', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit'
      });
    }

    return value;
  }

  const allowExpansion = (lesson) => {
    return learner.showResponses && lesson.complete === true;
  };

  const rowExpansionTemplate = (data) => {
    return <ViewResponses data={data} learnerId={learnerId} />;
  }

  const columns = [
    {name: 'title', display: 'Title', sortable: true},
    {name: 'complete', display: 'Completed', sortable: true, body: completeBody, bodyStyle: {textAlign: 'center'}, dataType: 'boolean'},
    {name: 'time', display: 'Time', sortable: true, bodyStyle: {textAlign: 'center'}},
    {name: 'retries', display: 'Retries', sortable: true, bodyStyle: {textAlign: 'center'}},
    {name: 'score', display: 'Score', sortable: true, bodyStyle: {textAlign: 'center'}},
    {name: 'date', display: 'Date', sortable: true, body: dateBody, bodyStyle: {textAlign: 'center'}, dataType: 'date'},
  ];


  // Component load actions
  useEffect(() => {
    changeActiveContent('Lessons');
  }, []);


  // Define functions
  function changeActiveContent(type) {
    setActiveTab(type);


    if( type === 'Lessons' ) {
      setActiveContent(
        <ViewLearnerLessons
          columns={columns}
          allowExpansion={allowExpansion}
          rowExpansionTemplate={rowExpansionTemplate}
          lessonData={learner.lessonData.lessons}
          firstName={firstName}
          showResponses={learner.showResponses}
        />
      );
    } else if( type === 'Courses' ) {
      setActiveContent(
        <ViewLearnerCourses
          columns={columns}
          allowExpansion={allowExpansion}
          rowExpansionTemplate={rowExpansionTemplate}
          courseData={learner.courseData}
          firstName={firstName}
          showResponses={learner.showResponses}
        />
      );
    }
  }


  return (
    <div>
      <div class='flex-row tabs-container'>

      <h3>
        <button
          type='button'
          class={activeTab === 'Lessons' ? 'tab active' : 'tab'}
          onClick={() => changeActiveContent('Lessons')}
        >Lessons</button>
      </h3>

      <h3>
        <button
          type='button'
          class={activeTab === 'Courses' ? 'tab active' : 'tab'}
          onClick={() => changeActiveContent('Courses')}
        >Courses</button>
      </h3>

      </div>

      {activeContent}
    </div>
  );
}

export default ViewLearnerData;