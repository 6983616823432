import PrimeTableSimple from "../utilities/table/primeTableSimple";


function ViewLearnerCourse(props) {

  // Define variables
  const course = props.course;
  const dataInitial = course.lessons;
  const firstName = props.firstName;
  const isIelts = course.isIelts;
  const score = course.score;
  const showResponses = props.showResponses;
  const columns = props.columns;
  const allowExpansion = props.allowExpansion;
  const rowExpansionTemplate = props.rowExpansionTemplate;
  const filename = `Lesson Data - ${course.title} - ${firstName}`;

  // Format date & time
  const data = [];
  dataInitial.forEach((lesson, index) => {
    if( lesson.date ) {
      const thisDate = new Date(lesson.date);
      lesson.date = thisDate;
    }
    lesson.time = lesson.time.replace('minute', 'min');
    data.push(lesson);
  });


  // Screen components
  let dateWord = 'Started';
  let date = course.createdAt;
  if( course.status === 'complete' ) {
    dateWord = 'Completed';
    date = course.updatedAt;
  } else if( course.status === 'cancelled' ) {
    dateWord = 'Cancelled';
    date = course.updatedAt;
  }

  let scoreElement;
  if( score ) {
    const displayCourseScore = `${course.score}${isIelts ? null : '%'}`;
    scoreElement = <p>Course score: {displayCourseScore}</p>;
  }


  return (
    <div style={{marginBottom: '60px'}}>
      <h4>{course.title}</h4>
      <p>Course status: {course.status}</p>
      {scoreElement}
      <p class="gray-text seconday-info" style={{fontSize: '.9rem'}}>
        {course.courseLessonsCompleted}
        <span style={{marginLeft: '1px', marginRight: '1px'}}>/</span>
        {course.courseLessonTotal} lessons completed
      </p>
      <p class="gray-text seconday-info" style={{marginBottom: '15px', fontSize: '.9rem'}}>
        {dateWord} {date}
      </p>

      <PrimeTableSimple
        columns={columns}
        data={data}
        allowExpansion={allowExpansion}
        rowExpansionTemplate={rowExpansionTemplate}
        searchPlaceholder={'Search by code, name, or email'}
        showResponses={showResponses}
        showExport={true}
        filename={filename}
      />

    </div>
  );
}

export default ViewLearnerCourse;