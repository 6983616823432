import AudioIcon from "../utilities/audioIcon";


function ViewResponse(props) {

  // Define variables
  const response = props.response;
  const score = response.score;
  const transcript = response.text;
  const audioUrl = response.audioUrl;


  // Screen componets
  let audioElement;
  if( audioUrl ) {
    audioElement = <AudioIcon audioUrl={audioUrl} width={'24px'} />
  }


  return(
    <div class='flex-row show-response-container'>
      <div class='flex-row'>
        <p>Score: {score}</p>
        <p>&nbsp; | &nbsp;</p>
        <p>{transcript}</p>
      </div>

      {audioElement}
    </div>
  );
}

export default ViewResponse;