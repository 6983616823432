import { useState, useEffect, useRef } from 'react';

import apiCall from '../../../helperFunctions/apiCall';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import 'primereact/resources/themes/lara-light-cyan/theme.css';

import ExcelDownloader from '../excelDownloader';

let interval;
const defaultLazyData = {
  first: 0,
  rows: 10,
  page: 0,
  sortField: 'code',
  sortOrder: 1,
  search: null,
  filters: {}
}


function PrimeTable(props) {

  // Define variables
  const inputRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [data, setData] = useState(null);
  const [lazyData, setLazyData] = useState(defaultLazyData);
  const [activSearch, setActivSearch] = useState(false);
  const organizationId = props.organizationId;
  const apiMethod = props.apiMethod;
  const columns = props.columns;
  const enableSearch = props.enableSearch || true;
  const searchPlaceholder = props.searchPlaceholder || 'Search';
  const filename = props.filename || 'export';
  const showExport = props.showExport || true;


  // Component load actions
  useEffect(() => {
    if( organizationId ) {
      loadData();
    }
  }, [organizationId, lazyData]);


  // Define functions
  function loadData() {

    const payload = {
      organizationId: organizationId,
      limit: lazyData.rows,
      page: lazyData.page,
      sortField: lazyData.sortField,
      sortOrder: lazyData.sortOrder,
      search: lazyData.search
    }

    apiCall(apiMethod, payload).then((response) => {
      if( response.success ) {

        const data = response.data;

        setTotalRecords(data.totalRecords);
        setData(data.data);

      } else {
        alert(response.error);
      }
    }).finally(() => {
      setTimeout(() => {
        setLoading(false);
      }, 700);
    });

  }

  function onPage(event) {
    setLoading(true);
    setLazyData({
      ...lazyData,
      page: event.page,
      rows: event.rows,
      first: event.first
    });
  }

  function onSort(event) {
    setLoading(true);
    setLazyData({...lazyData, sortField: event.sortField, sortOrder: (lazyData.sortOrder * -1)});
  }

  function onFilter(value) {
    clearInterval(interval);
    setActivSearch(true);

    interval = setInterval(function(){
      setSearchValue(value)
    }, 400);
  }

  function setSearchValue(value) {
    clearInterval(interval);
    setLazyData({...lazyData, first: 0, page: 0, search: value});
  }

  function clearSearch() {
    if( inputRef && inputRef.current ) {
      setActivSearch(false);
      inputRef.current.value = '';
    }
    setLazyData({...lazyData, first: 0, page: 0, search: null});
  }


  // Screen components
  let searchElement;
  if( enableSearch ) {

    let clearElement;
    if( activSearch ) {
      clearElement = (
        <button type='button' class='clear-search-button' onClick={() => clearSearch()}>
          <span class='icon icon-cancel'></span>
        </button>
      );
    }

    searchElement = (
      <div class='flex-row search-bar-container'>
        <input
          ref={inputRef}
          name="search"
          type="text"
          onChange={event => onFilter(event.target.value)}
          placeholder={searchPlaceholder}
          title="Type to search"
        />
        {clearElement}
      </div>
    );
  }

  let downloadElement;
  if( showExport ) {
    downloadElement = (
      <ExcelDownloader
        data={data}
        headings={columns}
        filename={filename}
        apiMethod={apiMethod}
        organizationId={organizationId}
        sortField={lazyData.sortField}
        sortOrder={lazyData.sortOrder}
      />
    );
  }


  return (
    <div style={{width: '100%'}}>
      <div class='flex-row'>
        {downloadElement}
        {searchElement}
      </div>

      <DataTable
        value={data}
        loading={loading}
        lazy
        paginator
        stripedRows
        filters={lazyData.filters}
        rows={lazyData.rows}
        first={lazyData.first}
        sortField={lazyData.sortField}
        sortOrder={lazyData.sortOrder}
        totalRecords={totalRecords}
        onPage={onPage}
        onSort={onSort}
        onFilter={onFilter}

        style={{width: '100%'}}

        emptyMessage={'No records found'}

        rowsPerPageOptions={[10, 15, 30, 50, 100]}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="{first} - {last} of {totalRecords} records"
      >
        {columns.map((column, i) => (
          <Column
            key={column.name}
            field={column.name}
            header={column.display}
            sortable={column.sortable}
            body={column.body ? column.body : null}
            headerStyle={column.headerStyle}
            bodyStyle={column.bodyStyle}
          />
        ))}
      </DataTable>

    </div>
  );
}

export default PrimeTable;